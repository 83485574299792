/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var CAE = {
        // All pages
        'common': {
            init: function () {

                init_wishlist();
                init_feedback_button();
                init_chosen(); // Select dropdown
                //init_mobile_content()
                init_search_buttons();
                init_fitvids(); // Responsive embed of <video> content
                //init_sticky_toggle();
                init_unveil(); // Lazy load images in normal / retina
                init_editprice();

                init_view_courses_buttons();

                $('.menu-toggle').click(function(e){
                    e.preventDefault();
                    $('body').toggleClass('nav-open');
                });

                $("#mega-feature-template").find(".mega-feature").detach().appendTo('nav li.mega-menu > ul.sub-menu');


            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                init_prevent_default(); // Prevent JS links from being clicked
            }
        },
        // Home page
        'home': {
            init: function () {
                $(window).on('load', function(){
                    $('.slickslider .slides').slick({
                        dots:true,
                        fade:true,
                        arrows:false 
                    });
                });

                // Autocomplete search box
                //init_autocomplete();
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = CAE;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    // Autocomplete search box
    function init_autocomplete(){
        $( "#course-name" ).autocomplete({
            source: function( request, response ) {
                $.ajax({
                    url: ajaxurl,
                    dataType: "json",
                    'method' : "post",
                    data: {
                        q: request.term, action: 'search'
                    },

                    beforeSend : function(jxhr, settings)
                    {
                             $('#home-serach-submit').hide();
                             $('#home-search-ajax').show();

                    },
                    success: function( data )
                    {

                        $('#home-serach-submit').show();
                        $('#home-search-ajax').hide();
                        response( data );
                    }
                });
            },
            minLength: 3,
            select: function( event, ui ) {
                console.log( ui.item );
                window.location.href = ui.item.id;
            },
            open: function() {
                $( this ).removeClass( "ui-corner-all" ).addClass( "ui-corner-top" );
            },
            close: function() {
                $( this ).removeClass( "ui-corner-top" ).addClass( "ui-corner-all" );
            }
        });
    }

    //Wishlist
    function init_wishlist(){
        function update_wishlist_count()
        {
            var wcount = $('.wishlist-item').length;

            $('.wishlist-bubble').text($('.wishlist-item').length);
            if(wcount > 0)
            {
                $('.wishlist .empty').hide();
                $('.wishlist .wishlist-viewall').show();
            }else
            {
                $('.wishlist .empty').show();
                $('.wishlist .wishlist-viewall').hide();

            }
        }

        $('.btn-close-wishlist').click(function(){
            $('.wishlist').hide();
        });


        var bubble = '';
        bubble += '<span class="wishlist-bubble">';
        bubble += '&nbsp;';
        bubble += '</span';

        $('.menu-wishlist').append(bubble);

        $('.menu-wishlist a').click(function(e)
        {
            e.preventDefault();
            $('.wishlist').toggle();
        });

        update_wishlist_count();


        $('.btn-email-wishlist').click(function(e)
        {
            e.preventDefault();
            $.ajax({
                url: ajaxurl,
                dataType: "json",
                'method' : "post",
                data: {
                    email: $('#exampleInputEmail1').val(), action: 'email_wishlist'
                },

                success: function( data )
                {
                    console.log(data) ;
                    if(data.status === 'sent')
                    {
                        $('.sent-msg-holder').addClass('sent-msg-holder-success').removeClass('sent-msg-holder-warning');
                        $('.sent-msg-holder').html('<i class="fa fa-check"></i> Wishlist sent').show();
                        $('#exampleInputEmail1').val('');

                    }
                    if(data.error === 'invalid')
                    {
                        $('.sent-msg-holder').removeClass('sent-msg-holder-success').addClass('sent-msg-holder-warning');
                        $('.sent-msg-holder').html('<i class="fa fa-times"></i> Invalid email').show();
                        $('#exampleInputEmail1').val('');

                    }
                }
            });
        });


        $(document).on('click', '.js-wishlist', function() {
            $.ajax({
                url: ajaxurl,
                dataType: "json",
                'method' : "post",
                data: {
                    cid: $(this).attr('data-course-id'), cname: $(this).attr('data-course-name'), action: 'add_to_wishlist'
                },

                success: function( data )
                {
                    if( $('.wishlist-items .item-id-'+data.cid).length === 0)
                    {

                        var listItem = '';

                        listItem += '<div class="wishlist-item item-id-' + data.cid + '">';
                        listItem +=  '<a href="'+ data.url  + '" class="wishlist-item-link">' + data.cname + '</a> <a href="javascript:;" class="btn-remove-from-wishlist"  data-course-id="' + data.cid  + '">x</a>';
                        listItem += '</div>';


                        $('.wishlist-items').append(listItem);
                        update_wishlist_count();
                    }
                    console.log(data) ;
                }
            });
        });


        $(document).on('click', 'a.btn-remove-from-wishlist', function()
        {
            $.ajax({
                url: ajaxurl,
                dataType: "json",
                'method' : "post",
                data: {
                    cid: $(this).attr('data-course-id'), action: 'remove_from__wishlist'
                },

                success: function( data )
                {

                    $('.wishlist-items .item-id-'+data.cid).remove();
                    update_wishlist_count();
                }
            });
        });

        $('.menu-wishlist').append($('.wishlist'));

    }

    // Feedback button
    function init_feedback_button(){
        $('.feedback-btn').click(function()
        {
            $('.feedback-form').toggle();
            $("html, body").animate({ scrollTop: $(document).height() }, "slow");
        });      
    }


    // Chosen plugin on select inputs
    function init_chosen(){
        if (typeof $.fn.chosen !== 'undefined') {
            $('.course-study-areas-select').change(function () {
                location.href = $('.course-study-areas-select option:selected').val();
            });

            $(".chosen").chosen();
        }

    }


    // Search buttons
    function init_search_buttons(){
        //unify search values
        $('#search-submit-btn, #search-update-btn').click(function(e)
        {
            var checked = '';

            e.preventDefault();

            $('.search-ptype:checked').each(function () {
               checked +=  $(this).val() +',';
            });

            $('#search-form-ptype').val(checked);

            $('#search-submit-form').submit();

        }) ;
    }

    // View All Course buttons
    function init_view_courses_buttons(){
        //unify search values
        $('#extraCoursebtn').click(function(e)
        {
            e.preventDefault();

            $('#extraCourse').removeClass("hidden");

            $('#extraCoursebtn').addClass("hidden");

        }) ;
    }



    // Fire up FitVids.js
    // Perhaps not on <body>
    function init_fitvids(){
        if( typeof $.fn.fitVids !== 'undefined' ) {
            $('body').fitVids();
        }
    }

    // Mobile content toggle
    // IF this is needed set debounce function so it works on screen rotate
    function init_mobile_content(){
        if( $(window).width() < 768 ) {
            $('.content .content-left')
                .height($(window).height() - $('.header-mobile-wrapper').height() - $('#wpadminbar').height() - 15);

            $('.content .toggle-left-sidebar').click(function() {
                $('.content .content-left').toggleClass('active');
                $('body').toggleClass('fixed');
            });

            $('.content .close-menu').click(function() {
                $('.content .content-left').removeClass('active');
                $('body').removeClass('fixed');
            });
        }      
    }


    // Remove sticky toggle on bootstrap nav
    function init_sticky_toggle(){
        $('.primary-menu > li').on('mouseover', function() {
            $('.primary-menu > li.open').removeClass('open').find('a').blur();
        });

        if( $(window).width() >= 992 && $('body').height() > ($(window).height() + 200) ) {
            $('#header .header-inner-wrapper').affix({
                offset: {
                top: 80
                }
            });
        }    
    }


    // Lazy load images in retina or normal resolution
    // Load image from 300px before entering viewport
    // Fade in using CSS transition
    function init_unveil(){
        $("img.unveil").unveil(300, function() {
            $(this).load(function() {
                this.style.opacity = 1;
            });
        });        

        // Edge case, browser window transferred to / from retina screen
        $(window).resize( debounce( function() {
            $("img.unveil").unveil();
        }, 456 ));
    }


    // Debounce function from UnderscoreJS
    function debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    }


    // Prevent relevant links from being clicked
    // Apply to front end only via "layout" class which wraps all content
    function init_prevent_default() {
        $(".layout a.js-null").on("click", function(event) {
            event.preventDefault();
        });
    }


    function init_editprice() {
        $('.button--price').click(function(e){
            e.preventDefault();
            console.log('adlfkhaf');
            $(this).parents('.fees').addClass('active');
        });
        $('.button--price-cancel').click(function(e){
            e.preventDefault();
            $(this).parents('.fees').removeClass('active');
        });
    }
})(jQuery); // Fully reference jQuery after this point.
